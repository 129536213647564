<template>
  <v-row
    v-if="errorData !== null"
    justify="center"
    class="fill-height text-center"
  >
    <v-col cols="12">
      <h1>{{ $t('errorDetail.title') }}</h1>
    </v-col>
    <v-col cols="12">
      <h1 class="mb-4">
        {{ errorData.carType.name }}
      </h1>
      <h2 class="mb-4">
        {{ $t('errorDetail.count') }}: {{ errorData.neededMaintainers }}
      </h2>
      <p>{{ $t('errorDetail.errDesc') }}: {{ errorData.description }}</p>
    </v-col>
    <v-col cols="12">
      <v-btn
        v-if="errorData.canBeClaimed"
        x-large
        color="primary"
        @click="claimTask"
      >
        {{ $t('errorDetail.claim') }}
      </v-btn>
    </v-col>
    <v-row
      align="center"
      justify="center"
    >
      <v-col sm="3">
        <v-alert
          v-if="error"
          max-width="400"
          type="error"
          dismissible
        >
          {{ $t('errorDetail.claimError') }}
        </v-alert>
        <v-alert
          v-if="success"
          style="cursor: pointer;"
          max-width="400"
          type="success"
          dismissible
          @click="goToTask"
        >
          {{ $t('errorDetail.claimOK') }}
        </v-alert>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>

export default {
  name: 'ErrorDetail',
  data () {
    return {
      errorData: null,
      success: false,
      error: false,
      taskData: null
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const { data } = await this.$axios.get(`/car-errors/${this.$route.params.id}`)
        this.errorData = data
      } catch (err) {
        console.error(err)
      }
    },
    async claimTask () {
      try {
        this.success = false
        this.error = false
        const sendData = {
          startedAt: new Date().toISOString()
        }
        const { data } = await this.$axios.post(`/car-errors/${this.$route.params.id}/claim-error`, sendData)
        this.taskData = data
        this.success = true
        this.goToTask()
      } catch (err) {
        this.error = true
        console.log(err)
      }
    },
    goToTask () {
      this.$router.push({ name: 'TaskDetailCar', params: { id: this.taskData.id } })
    }
  }
}
</script>
